import React, { useState } from "react";
import { FaEllipsisH, FaHome } from "react-icons/fa";
import { CardContainer, EllipsisContainer } from "./GroupCardStyles";
import { Link } from "react-router-dom";
import { getIcon } from "../../../utils/iconsFormat";
import GroupEditModal from "../GroupEditModal/GroupEditModal";

type GroupCardProps = {
  title: string;
  totalExpenses: number;
  balance: number;
  icon: React.ReactNode;
  groupId: string;
};

const GroupCard = ({
  title,
  totalExpenses,
  balance,
  icon,
  groupId,
}: GroupCardProps) => {
  const [editIsVisible, setEditIsVisible] = useState(false);

  return (
    <>
      <CardContainer>
        <div>
          <div style={{ fontSize: "40px" }}>{getIcon(icon)}</div>

          <Link to={`/groups/${groupId}`}>
            <h2>{title}</h2>
          </Link>
          <p>Total Expenses:${totalExpenses.toLocaleString()}</p>
          <p>Balance: ${balance.toLocaleString()}</p>
        </div>
        <div>
          <EllipsisContainer>
            <FaEllipsisH onClick={() => setEditIsVisible(!editIsVisible)} />
            {editIsVisible && (
              <GroupEditModal
                setEditIsVisible={setEditIsVisible}
                groupId={groupId}
              />
            )}
          </EllipsisContainer>
        </div>
      </CardContainer>
    </>
  );
};

export default GroupCard;
