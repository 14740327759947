import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkGrey};
  padding: 20px;
  width: 100%;
  border-radius: 10px;
`;

export const Header = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const BarContainer = styled.div`
  width: 90%;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 10px;
  overflow: hidden;
`;

export const Bar = styled.div<{ percentage: number }>`
  height: 20px;
  width: ${({ percentage }) => percentage}%;
  background-color: ${({ theme }) => theme.colors.yellow};
  transition: width 0.3s ease;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 10px;
`;
