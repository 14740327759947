import React from "react";
import styled from "styled-components";
import {
  Bar,
  BarContainer,
  Container,
  Header,
  Info,
} from "./ProgressBarStyles";

interface ProgressBarProps {
  name: string;
  spent: number;
  budget: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ name, spent, budget }) => {
  const percentage = Math.min((spent / budget) * 100, 100);

  return (
    <Container>
      <Header>{name}</Header>
      <BarContainer>
        <Bar percentage={percentage} />
      </BarContainer>
      <Info>
        <div>Gastado: ${spent.toLocaleString()}</div>
        <div>${budget.toLocaleString()}</div>
      </Info>
    </Container>
  );
};

export default ProgressBar;
