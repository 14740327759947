import api from "./api";

export const loginService = async (email: string, password: string) => {
  const response = await api.post("/auth/login", { email, password });

  return response.data;
};

export const registerService = async (form: any) => {
  const response = await api.post("/auth/register", form);
  return response;
};

export const validateTokenService = async (token: string) => {
  const response = await api.post("/auth/validate", null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
