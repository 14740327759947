// src/pages/GroupPage.tsx

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../context/store";
import groupService from "../../services/groupService";
import { Link } from "react-router-dom";
import GroupCard from "../../components/Common/GroupCard/GroupCard";
import { Button, GroupsContainer } from "./GroupPageStyles";
import { FaPlane, FaPlus } from "react-icons/fa";
import NewGroupModal from "../../components/NewGroupModal/NewGroupModal";

const GroupPage: React.FC = () => {
  const [groups, setGroups] = useState<any>([]);
  const userId = useSelector((state: any) => state.auth.user?.id);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const fetchGroups = async () => {
    console.log(userId);
    if (!userId) {
      console.log("no hay usuarId");
      return;
    }
    const response = await groupService.getGroups(userId);
    setGroups(response.data);
    console.log(response.data);
  };

  useEffect(() => {
    fetchGroups();
  }, [userId, groups]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 style={{ color: "white" }}>Grupos</h1>

        <Button onClick={() => setModalIsOpen(!modalIsOpen)}>
          <FaPlus /> Crear un grupo
        </Button>
      </div>
      <GroupsContainer>
        {groups.length === 0 ? (
          <h3>No groups found</h3>
        ) : (
          groups.map((group: any) => (
            <GroupCard
              title={group.name}
              totalExpenses={group.totalExpenses}
              balance={group.balance}
              icon={group.icon}
              groupId={group._id}
            />
          ))
        )}
      </GroupsContainer>
      {modalIsOpen && <NewGroupModal setModalIsOpen={setModalIsOpen} />}
    </>
  );
};

export default GroupPage;
