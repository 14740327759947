import React, { useState, useEffect } from "react";
import {
  CategoryModalCard,
  CategoryModalContainer,
} from "./CategoryModalStyles";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../../context/notificationSlice";
import categoryService from "../../../services/categoryService";
import { getIcon } from "../../../utils/iconsFormat";
import IconSelectorModal from "../../IconSelectorModal/IconSelectorModal";

const CategoryModal = ({
  setCategoryIsOpen,
  groupId,
  onCategorySelect,
}: any) => {
  const dispatch = useDispatch();

  const userId = useSelector((state: any) => state.auth.user.id);

  const [newCategoryName, setNewCategoryName] = useState("");
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedIcon, setSelectedIcon] = useState("image");
  const [isIconModalOpen, setIsIconModalOpen] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await categoryService.getAllCategories(groupId);
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories", error);
        dispatch(
          addNotification({
            message: "Error al obtener categorías",
            type: "Error",
          })
        );
      }
    };

    fetchCategories();
  }, [groupId, dispatch]);

  const handleAddCategory = async () => {
    if (newCategoryName.trim() === "") {
      dispatch(
        addNotification({
          message: "El nombre de la categoría no puede estar vacío",
          type: "Error",
        })
      );
      return;
    }
    try {
      const { data: newCategory } = await categoryService.addCategory({
        groupId,
        name: newCategoryName,
        icon: selectedIcon,
        userId,
        budget: 10,
      });
      setCategories([...categories, newCategory]);
      setNewCategoryName("");
      dispatch(
        addNotification({
          message: "Categoría agregada exitosamente",
          type: "Success",
        })
      );
    } catch (error) {
      console.error("Error adding category", error);
      dispatch(
        addNotification({
          message: "No se pudo agregar la categoría",
          type: "Error",
        })
      );
    }
  };

  const handleIconSelect = (iconName: any) => {
    setSelectedIcon(iconName);
  };

  const handleCategorySelect = (category: any) => {
    onCategorySelect(category);
    setCategoryIsOpen(false);
  };

  return (
    <CategoryModalContainer>
      <CategoryModalCard>
        <div onClick={() => setCategoryIsOpen(false)}>
          <FaTimes />
        </div>
        <h3>Selecciona una categoria</h3>
        <div>
          <input
            type="text"
            placeholder="Nueva Categoria"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
          <button onClick={handleAddCategory}>
            <FaPlus />
          </button>
          <button onClick={() => setIsIconModalOpen(true)}>
            {selectedIcon ? getIcon(selectedIcon) : <FaPlus />}
          </button>
        </div>
        <div>
          <ul>
            {categories.length > 0 ? (
              categories.map((category: any) => (
                <li
                  key={category._id}
                  onClick={() => handleCategorySelect(category)}
                >
                  {category.name}
                  <span>{getIcon(category.icon)}</span>
                </li>
              ))
            ) : (
              <li>No hay categorías disponibles</li>
            )}
          </ul>
        </div>
      </CategoryModalCard>
      {isIconModalOpen && (
        <IconSelectorModal
          onClose={() => setIsIconModalOpen(false)}
          onIconSelect={handleIconSelect}
        />
      )}
    </CategoryModalContainer>
  );
};

export default CategoryModal;
