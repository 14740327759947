import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { validateTokenService } from "./services/authService";
import { loginSuccess, logoutSuccess } from "./context/authSlice";
import AppRoutes from "./routes/Routes";
import NotificationAlert from "./components/Common/NotificationAlert";
import "./styles/global.css";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken && refreshToken) {
        try {
          const response = await validateTokenService(accessToken);

          if (response.user) {
            const user = response.user;
            dispatch(loginSuccess({ accessToken, refreshToken, user }));
          } else {
            dispatch(logoutSuccess());
          }
        } catch (error) {
          console.error("Token validation failed", error);
          dispatch(logoutSuccess());
        }
      } else {
        dispatch(logoutSuccess());
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Optionally use a spinner or loading component
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppRoutes />
        <NotificationAlert />
      </ThemeProvider>
    </Router>
  );
};

export default App;
