import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Notification = {
  message?: string;
  title?: string;
  isVisible?: boolean;
  type?: "Success" | "Error" | "Warning";
};

const initialState: Notification = {
  message: "",
  title: "",
  isVisible: false,
  type: "Error",
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.isVisible = true;
      state.type = action.payload.type;
    },
    removeNotification: (state, action: PayloadAction<Notification>) => {
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.isVisible = false;
      state.type = action.payload.type;
    },
  },
});

export const { removeNotification, addNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
