import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import DashboardPage from "../pages/Dashboard/DashboardPage";
import GroupPage from "../pages/Groups/GroupPage";
import GroupDetailPage from "../pages/GroupDetail/GroupDetailPage";
import ActivityPage from "../pages/ActivityPage";
import AccountPage from "../pages/Account/AccountPage";
import BudgetPage from "../pages/BudgetPage";
import ReportsPage from "../pages/ReportsPage";
import NotificationsPage from "../pages/NotificationsPage";
import IntegrationsPage from "../pages/IntegrationsPage";
import HistoryPage from "../pages/HistoryPage";

import Navbar from "../components/Common/Navbar/Navbar";
import PrivateRoutes from "./PrivateRoutes";
import {
  MainContainer,
  ContentContainer,
} from "../components/Common/Navbar/NavbarStyles";

const AppLayout: React.FC = () => (
  <MainContainer>
    <Navbar />
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  </MainContainer>
);

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="*"
          element={
            <PrivateRoutes>
              <Routes>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/groups" element={<GroupPage />} />
                <Route path="/groups/:id" element={<GroupDetailPage />} />
                <Route path="/activity" element={<ActivityPage />} />
                <Route path="/cuentas" element={<AccountPage />} />
                <Route path="/budget" element={<BudgetPage />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/integrations" element={<IntegrationsPage />} />
                <Route path="/history" element={<HistoryPage />} />
              </Routes>
            </PrivateRoutes>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
