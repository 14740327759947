import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.darkGrey};
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const MainCard = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  flex: 1;
  margin: 0 10px;
`;

export const ProgressBarContainer = styled.div`
  height: 250px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.yellow};
    border-radius: 6px;
  }
`;

export const ExpensesContainer = styled.div`
  height: 250px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.yellow};
    border-radius: 6px;
  }
`;
