import styled from "styled-components";

export const ModalContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  min-width: 150px;
  background-color: ${(props) => props.theme.colors.darkGrey};
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.colors.lightGrey};
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;
