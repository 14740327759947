import { useSelector } from "react-redux";
import { RootState } from "../context/store";
import { Navigate } from "react-router-dom";

const PrivateRoutes = ({ children }: any) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoutes;
