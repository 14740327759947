import styled from "styled-components";

export const AddExpenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 20%;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 20px;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  input,
  select {
    ${({ theme }) => theme.input};
    width: calc(100% - 40px);
  }

  button {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.yellow};
    padding: 12px 20px;
    border: none !important;
    margin: 10px 0px;
    border-radius: 10px;
  }

  button:first-child {
    border: none;
    background-color: ${({ theme }) => theme.colors.red};
    border: 1px solid ${({ theme }) => theme.colors.white};
  }

  svg {
    color: black;
  }
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
`;
