import React from "react";
import { FaHome } from "react-icons/fa";
import { Container } from "./ExpenseCardStyles";
import { getIcon } from "../../../utils/iconsFormat";
import { formatDate } from "../../../utils/formatDate";
import { formatCurrency } from "../../../utils/formatCurrency";

interface IExpenseCard {
  icon: string;
  userName: string;
  groupName: string;
  balance: string;
  date: string;
  expenseName: string;
  categoryId: { name: string };
}

const ExpenseCard = ({
  icon = "camera",
  balance = "0",
  date = "12/12/2024",
  expenseName = "ExpenseName",
  categoryId = { name: "Sin Categoria" },
}: IExpenseCard) => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <div>{getIcon(icon)}</div>
          <h4>{expenseName}</h4>
          <p>
            Categoria: {categoryId.name} - {formatDate(new Date(date))}
          </p>
          <p></p>
        </div>
        <div>
          <h4 style={{ color: "green" }}>
            {formatCurrency(balance.toString())}
          </h4>
        </div>
      </div>
    </Container>
  );
};

export default ExpenseCard;
