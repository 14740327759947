import React, { useEffect, useState } from "react";
import expenseService from "../services/expenseService";
import "./ActivityPage.css";
import { useSelector } from "react-redux";
import { RootState } from "../context/store";

interface User {
  _id: string;
  name: string;
  email: string;
  phone: string;
}

interface SplitDetail {
  userId?: User; // userId is optional to handle undefined cases
  percentage: number;
}

interface Group {
  _id: string;
  name: string;
  type: string;
  createdAt: string;
  members: User[];
  userOwner: string;
  updatedAt: string;
}

interface Activity {
  _id: string;
  name: string;
  amount: number;
  date: string;
  paidBy: string;
  splitMethod: string;
  splitDetails: SplitDetail[];
  groupId: Group;
}

const ActivityPage: React.FC = () => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  //eslint-disable-next-line
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const selector = useSelector((state: any) => state.auth.user);
  const userId = selector._id;

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await expenseService.getExpenses(userId);
        const { data }: any = response;
        console.log(data);
        setActivities(data.data);
        setTotalExpenses(data.total);
        setLoading(false);
        calculateTotalAmount(data.data);
      } catch (error) {
        setError("Failed to fetch activities");
        setLoading(false);
      }
    };

    fetchActivities();
  }, [limit, offset, userId]);

  const calculateTotalAmount = (activities: Activity[]) => {
    const total = activities.reduce(
      (acc, activity) => acc + activity.amount,
      0
    );
    setTotalAmount(total);
  };

  const handleDeleteExpense = async (id: string) => {
    try {
      await expenseService.deleteExpense(id);
      const updatedActivities = activities.filter(
        (activity) => activity._id !== id
      );
      setActivities(updatedActivities);
      calculateTotalAmount(updatedActivities);
    } catch (error) {
      setError("Failed to delete expense");
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalExpenses / limit);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="activity-page">
      <h2>Activities</h2>
      <table className="activity-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Paid By</th>
            <th>Split Method</th>
            <th>Split Details</th>
            <th>Group Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => (
            <tr key={activity._id}>
              <td>{activity.name}</td>
              <td>${activity.amount}</td>
              <td>{new Date(activity.date).toLocaleDateString()}</td>
              <td>{activity.paidBy}</td>
              <td>{activity.splitMethod}</td>
              <td>
                <ul>
                  {activity.splitDetails.map((detail, index) => (
                    <li key={index}>
                      {detail.userId
                        ? `${detail.userId.name} (${detail.userId.email})`
                        : "Unknown User"}
                      : {detail.percentage}%
                    </li>
                  ))}
                </ul>
              </td>
              <td>{activity.groupId.name}</td>
              <td>
                <button onClick={() => handleDeleteExpense(activity._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={1} style={{ textAlign: "right" }}>
              Total:
            </td>
            <td colSpan={7}>${totalAmount}</td>
          </tr>
        </tfoot>
      </table>
      <div className="pagination">
        {currentPage > 1 && (
          <p onClick={() => handlePageChange(currentPage - 1)}>Anterior</p>
        )}
        {pageNumbers.map((number) => (
          <p
            key={number}
            onClick={() => handlePageChange(number)}
            className={number === currentPage ? "active" : ""}
          >
            {number}
          </p>
        ))}
        {currentPage < totalPages && (
          <p onClick={() => handlePageChange(currentPage + 1)}>Siguiente</p>
        )}
      </div>
    </div>
  );
};

export default ActivityPage;
