import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 20px;
  width: calc(100%-30px);
  border-radius: 10px;
  margin: 20px 0px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }

  svg {
    font-size: 40px;
  }

  p {
    margin: 10px 0px;
  }
`;
