// src/services/groupService.ts

import api from "./api";

const getGroups = (userId: string) => {
  return api.get(`/groups?userId=${userId}`);
};

const getGroupById = (id: string) => {
  return api.get(`/groups/${id}`);
};

const addUserToGroup = (groupId: string, email: string) => {
  return api.post(`/groups/${groupId}/users`, { email });
};

const removeUserFromGroup = (groupId: string, userId: string) => {
  return api.delete(`/groups/${groupId}/users/${userId}`);
};

const updateGroup = (groupId: string, form: {}) => {
  return api.put(`/groups/${groupId}`, form);
};

const calculateDebts = (groupId: string) => {
  return api.get(`/groups/calculateDebts/${groupId}`);
};

const createGroup = (groupData: {
  name: string;
  budget: number;
  userOwner: string;
  members: string[];
  icon: string;
}) => {
  return api.post("/groups", groupData);
};

const checkUserByEmail = (email: string) => {
  return api.post("/users/check", { email: email });
};

const deleteGroup = (groupId: string) => {
  return api.delete(`/groups/${groupId}`);
};

const groupService = {
  getGroups,
  getGroupById,
  addUserToGroup,
  removeUserFromGroup,
  updateGroup,
  calculateDebts,
  createGroup,
  checkUserByEmail,
  deleteGroup,
};

export default groupService;
