import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import groupService from "../../services/groupService";
import { formatDate } from "../../utils/formatDate";
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar";
import ExpenseCard from "../../components/Common/ExpenseCard/ExpenseCard";
import { ExpenseCardContainer, Header } from "./GroupDetailPageStyles";
import AddExpense from "../../components/Common/AddExpense/AddExpense";

interface User {
  _id: string;
  email: string;
}

interface Group {
  _id: string;
  name: string;
  type: string;
  createdAt: string;
  members: User[];
  userOwner: string;
  updatedAt: string;
}

const GroupDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [addIsVisible, setAddIsVisible] = useState(false);
  const [expenses, setExpenses] = useState<any>({
    name: "",
    budget: 0,
    totalExpenses: 0,
  });

  const fetchGroup = async (id: string) => {
    try {
      const { data } = await groupService.getGroupById(id);
      console.log(data, 1);
      setExpenses(data);
    } catch (error) {
      console.error("Failed to fetch group details", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchGroup(id);
    }
    //eslint-disable-next-line
  }, [id]);

  const handleExpenseAdded = () => {
    console.log("handleExpenseAdded");
    fetchGroup(id as string);
  };

  return (
    <>
      <Header>
        <h1>Detalle del grupo</h1>
        <div>
          <button
            onClick={() => {
              setAddIsVisible(true);
            }}
          >
            Crear Gasto
          </button>
          <button>Ver Categorias</button>
          <button>Miembros</button>
          <button>Presupuesto</button>
        </div>
      </Header>
      <h2 style={{ color: "white" }}> {expenses.name}</h2>
      <ProgressBar
        name=""
        spent={expenses.totalExpenses}
        budget={expenses.budget}
      />
      <ExpenseCardContainer>
        {expenses.expenses &&
          expenses.expenses.map((item: any, index: any) => (
            <>
              {console.log(item)}
              <ExpenseCard
                icon={item.icon}
                userName={item.paidBy.name}
                groupName={item.groupId.name}
                balance={item.amount}
                date={item.date}
                expenseName={item.name}
                categoryId={item.categoryId}
              />
            </>
          ))}
      </ExpenseCardContainer>
      {addIsVisible && (
        <AddExpense
          setAddIsVisible={setAddIsVisible}
          groupId={id}
          onExpenseAdded={handleExpenseAdded}
        />
      )}
    </>
  );
};

export default GroupDetailPage;
