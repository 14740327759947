// src/utils/formatDate.ts

export const formatDate = (date: Date): string => {
  const day: string = date.getDate().toString().padStart(2, "0");
  const monthNames: string[] = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const month: string = monthNames[date.getMonth()];
  const year: string = date.getFullYear().toString();

  return `${day} de ${month} del ${year}`;
};
