import styled from "styled-components";

export const CategoryModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const CategoryModalCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  border-radius: 15px;
  min-width: 500px;

  div:first-child {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
  }
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  input {
    width: calc(100% - 80px);
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-right: 15px;
  }

  button {
    background-color: ${({ theme }) => theme.colors.yellow};
    border: none;
    padding: 10px;
  }

  ul {
    margin-top: 50px;
    list-style: none;
  }
`;
