import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 10px;
  padding: 20px;
  width: 200px;
`;

export const CircularChart = styled.svg`
  display: block;
  margin: 10px auto;
  max-width: 80%;
`;

export const CircleBG = styled.path`
  fill: none;
  stroke: ${({ theme }) => theme.colors.lightGrey};
  stroke-width: 3.8;
`;

export const Circle = styled.path<{ percentage: number }>`
  fill: none;
  stroke-width: 2.8;
  stroke: ${({ theme }) => theme.colors.yellow};
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
  stroke-dasharray: ${({ percentage }) => `${percentage}, 100`};
`;

export const PercentageText = styled.text`
  font-size: 0.5em;
  text-anchor: middle;
  fill: ${({ theme }) => theme.colors.white};
`;

export const BudgetInfo = styled.div`
  text-align: center;
`;
