import api from "./api";

export interface ICategory {
  _id?: string;
  name: string;
  budget?: number;
  icon: string;
  userId: any;
  createdAt?: Date;
  updatedAt?: Date;
  groupId?: any;
  categoryExpense?: number;
}

const getAllCategories = (groupId: string) => {
  return api.get(`/categories/${groupId}`);
};

const getCategories = (groupId: string) => {
  return api.get(`/categories/groupId/${groupId}`);
};

const getCategoryById = (id: string) => {
  return api.get(`/categories/${id}`);
};

const addCategory = (category: ICategory) => {
  return api.post("/categories", category);
};

const updateCategory = (id: string, category: Partial<ICategory>) => {
  // Use Partial to allow updating any subset of fields
  return api.put(`/categories/${id}`, category);
};

const deleteCategory = (id: string) => {
  return api.delete(`/categories/${id}`);
};

const categoryService = {
  getCategories,
  getCategoryById,
  addCategory,
  updateCategory,
  deleteCategory,
  getAllCategories,
};

export default categoryService;
