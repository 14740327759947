import React, { useState } from "react";
import { ModalContainer } from "./GroupEditModalStyles";
import groupService from "../../../services/groupService";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../context/notificationSlice";
import { useNavigate } from "react-router-dom";

const GroupEditModal = ({ setEditIsVisible, groupId }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkGroup = async () => {
    navigate(`/groups/${groupId}`);
    setEditIsVisible(false);
  };

  const deleteGroup = async () => {
    try {
      await groupService.deleteGroup(groupId);
      dispatch(
        addNotification({ message: "Grupo eliminado", type: "Success" })
      );
      setEditIsVisible(false);
    } catch (error) {
      dispatch(
        addNotification({
          message: "Error al eliminar el grupo",
          type: "Error",
        })
      );
    }
  };

  return (
    <ModalContainer>
      <ul>
        <li
          onClick={() => {
            checkGroup();
          }}
        >
          Ver Grupo
        </li>
        <li>Editar Grupo</li>
        <li onClick={() => deleteGroup()}>Eliminar Grupo</li>
      </ul>
    </ModalContainer>
  );
};

export default GroupEditModal;
