import styled from "styled-components";

export const Container = styled.div`
  width: 20vh;
  height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h2 {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  ${({ theme }) => theme.button.primary}

  &:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hover.backgroundColor};
    color: ${({ theme }) => theme.button.primary.hover.color};
  }
`;

export const Input = styled.input`
  ${({ theme }) => theme.input}
  width: calc(100% - 20px);
  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 10px;
  display: flex;
  padding: 30px;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
    animation: 1s linear infinite;
  }
  svg {
    font-size: 50px;
  }
`;

export const InviteMembers = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.white};

  input {
    margin: 0px;
    margin-right: 10px;
  }
  button {
    ${({ theme }) => theme.button.primary}
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) =>
        theme.button.primary.hover.backgroundColor};
      color: ${({ theme }) => theme.button.primary.hover.color};
    }
  }
`;

export const MembersList = styled.div`
  h4 {
    color: ${({ theme }) => theme.colors.white};
  }
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
    li {
      color: ${({ theme }) => theme.colors.white};
      margin: 10px 0px;
    }
  }
`;
