import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 50px);

  h4 {
    text-align: center;
    color: #323232;
    margin-top: 20px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  div:first-child {
    width: 20%;
    background-color: #f2f2f2;
    padding: 50px;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
  }

  div:last-child {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      color: #eec800;
    }
    p {
      color: white;
    }
  }

  button {
    background-color: #eec800;
    color: #323232;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 10px 0px;
  }
  button:hover {
    background-color: #e0b800;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #b3b3b3;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px 0px;

  &:focus-within {
    border-color: #eec800;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #f2f2f2;
  }

  input:focus {
    outline: none;
  }

  svg {
    color: #b3b3b3;
  }
`;
