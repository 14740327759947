import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../context/store";
import { removeNotification } from "../../context/notificationSlice";
import "./NotificationAlert.css";

const NotificationAlert: any = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: RootState) => state.notifications);

  if (notifications.isVisible) {
    setTimeout(() => {
      dispatch(removeNotification({ message: "", title: "" }));
    }, 5000);
  }

  useEffect(() => {
    if (notifications.isVisible) {
      setTimeout(() => {
        dispatch(
          removeNotification({ message: "", title: "", isVisible: false })
        );
      }, 5000);
    }
  }, [notifications, dispatch]);

  return (
    <>
      {notifications.isVisible ? (
        <div className="notification-container">
          <div className="notification-alert">
            <div className="notification-header">
              <span className="notification-title">{notifications.title}</span>
              <button className="close-button">×</button>
            </div>
            <div className="notification-body">
              <p>{notifications.message}</p>
            </div>
          </div>
        </div>
      ) : (
        <span></span>
      )}
    </>
  );
};

export default NotificationAlert;
