import React, { useState } from "react";
import {
  Button,
  Container,
  IconContainer,
  Input,
  InviteMembers,
  MembersList,
} from "./NewGroupModalStyles";
import { FaCamera, FaTimes, FaTrash } from "react-icons/fa";
import IconSelectorModal from "../IconSelectorModal/IconSelectorModal";
import { getIcon } from "../../utils/iconsFormat";
import groupService from "../../services/groupService";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../context/notificationSlice";

const NewGroupModal = ({ setModalIsOpen }: any) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state.auth.user.id);
  const userName = useSelector((state: any) => state.auth.user.name);

  const [groupName, setGroupName] = useState("");
  const [budget, setBudget] = useState("");
  const [email, setEmail] = useState("");
  const [members, setMembers] = useState<any>([{ name: userName, userId }]);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formattedAmount, setFormattedAmount] = useState("");

  const handleIconSelect = (iconName: any) => {
    setSelectedIcon(iconName);
  };

  const handleAddMember = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      dispatch(
        addNotification({
          message: "Por favor, ingrese un correo electrónico válido",
          type: "Error",
        })
      );
      return;
    }

    try {
      const { data } = await groupService.checkUserByEmail(email);

      if (data.exists) {
        if (members.some((member: any) => member.userId === data.user._id)) {
          dispatch(
            addNotification({ message: "El miembro ya existe", type: "Error" })
          );
        } else {
          setMembers([
            ...members,
            { name: data.user.name, userId: data.user._id },
          ]);
          setEmail("");
        }
      } else {
        const newMember = { name: email, userId: "pending" };
        setMembers([...members, newMember]);

        // await userService.sendInvitationEmail(email);

        dispatch(
          addNotification({ message: "Invitación enviada", type: "Success" })
        );
        setEmail("");
      }
    } catch (err) {
      console.error("Error al agregar miembro", err);
      dispatch(
        addNotification({
          message: "No se pudo agregar el miembro",
          type: "Error",
        })
      );
    }
  };

  const handleRemoveMember = (id: any) => {
    setMembers(members.filter((member: any) => member.userId !== id));
  };

  const handleCreateGroup = async () => {
    if (!groupName || !budget) {
      dispatch(
        addNotification({
          message: "Por favor, completa todos los campos",
          type: "Error",
        })
      );
      return;
    }

    if (members.length === 0) {
      dispatch(
        addNotification({
          message: "El grupo debe tener al menos un miembro",
          type: "Error",
        })
      );
      return;
    }

    try {
      const memberIds = members
        .filter((member: any) => member.userId !== "pending")
        .map((member: any) => member.userId);

      const response = await groupService.createGroup({
        name: groupName,
        budget: parseFloat(budget.replace(/\./g, "")),
        members: memberIds,
        icon: selectedIcon,
        userOwner: userId,
      });

      if (response.data) {
        dispatch(
          addNotification({
            message: "Grupo creado exitosamente",
            type: "Success",
          })
        );
        setGroupName("");
        setBudget("");
        setFormattedAmount("");
        setMembers([{ name: userName, userId }]);
        setSelectedIcon("");
        setModalIsOpen(false);
      }
    } catch (err: any) {
      console.error("Error al crear grupo", err);
      dispatch(
        addNotification({ message: "No se pudo crear el grupo", type: "Error" })
      );
    }
  };

  const handleAmountChange = (value: any) => {
    const cleanedValue = value.replace(/\D/g, "");
    const formattedValue = new Intl.NumberFormat().format(cleanedValue);
    setBudget(cleanedValue);
    setFormattedAmount(formattedValue);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setGroupName("");
    setBudget("");
    setFormattedAmount("");
    setMembers([{ name: userName, userId }]);
    setSelectedIcon("");
  };

  return (
    <Container>
      <div>
        <div style={{ textAlign: "right" }}>
          <FaTimes
            onClick={closeModal}
            style={{ color: "white", fontSize: "20px" }}
          />
        </div>
        <h2>Crear Grupo</h2>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <IconContainer onClick={() => setIsModalOpen(true)}>
            {selectedIcon ? getIcon(selectedIcon) : <FaCamera />}
          </IconContainer>
        </div>
        <Input
          type="text"
          placeholder="Nombre del grupo"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Presupuesto"
          value={formattedAmount}
          onChange={(e) => handleAmountChange(e.target.value)}
        />
        <InviteMembers>
          <Input
            type="email"
            placeholder="Correo Electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleAddMember}>Invitar</button>
        </InviteMembers>
        <MembersList>
          <h4>Miembros del grupo</h4>
          <ul>
            {members.length > 0 ? (
              members.map((member: any) => (
                <li key={member.userId}>
                  {member.name}
                  {member.userId !== "pending" && (
                    <button onClick={() => handleRemoveMember(member.userId)}>
                      <FaTrash />
                    </button>
                  )}
                </li>
              ))
            ) : (
              <li>No hay miembros agregados</li>
            )}
          </ul>
        </MembersList>
      </div>
      <Button onClick={handleCreateGroup}>Crear Grupo</Button>
      {isModalOpen && (
        <IconSelectorModal
          onClose={() => setIsModalOpen(false)}
          onIconSelect={handleIconSelect}
        />
      )}
    </Container>
  );
};

export default NewGroupModal;
