import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  max-width: 250px;
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 30px;
  border-radius: 10px;
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.colors.red};
    cursor: pointer;
    h2 {
      color: white;
    }
  }

  a {
    text-decoration: none;
  }

  h2 {
    color: ${({ theme }) => theme.colors.red};
  }

  h2,
  p {
    margin: 10px 0px;
  }
`;

export const EllipsisContainer = styled.div`
  position: relative;
  display: inline-block;

  svg {
    cursor: pointer;
  }
`;
