import React, { useState } from "react";
import { getIcon } from "../../utils/iconsFormat";
import { Box, Container, Grid, GridItem } from "./IconSelectorModalStyles";

const icons = [
  "cutlery", // Restaurantes y comida
  "beer", // Bebidas y entretenimiento
  "home", // Hogar
  "paw", // Mascotas
  "child", // Niños y familia
  "bank", // Finanzas y banco
  "users", // Comunidad o grupos
  "ship", // Viajes
  "money", // Dinero y pagos
  "music", // Música y entretenimiento
  "car", // Transporte y automóvil
  "shopping-cart", // Compras y supermercado
  "hotel", // Alojamiento y viajes
  "plane", // Vuelos y viajes
  "graduation-cap", // Educación y formación
  "briefcase", // Trabajo y negocios
  "medkit", // Salud y cuidados médicos
  "gift", // Regalos y ocasiones especiales
  "camera", // Fotografía y recuerdos
  "film", // Películas y entretenimiento
];

const IconSelectorModal = ({ onClose, onIconSelect }: any) => {
  const handleIconClick = (iconName: any) => {
    onIconSelect(iconName);
    onClose();
  };

  return (
    <Container>
      <Box>
        <h1>Seleccione El Icono</h1>
        <Grid>
          {icons.map((iconName) => (
            <GridItem key={iconName} onClick={() => handleIconClick(iconName)}>
              {getIcon(iconName)}
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default IconSelectorModal;
