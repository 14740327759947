import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: white;

  button {
    ${({ theme }) => theme.button.primary}
    margin: 0px 10px;

    &:hover {
      background-color: ${({ theme }) =>
        theme.button.primary.hover.backgroundColor};
      color: ${({ theme }) => theme.button.primary.hover.color};
    }
  }
`;

export const ExpenseCardContainer = styled.div`
  max-height: 600px;
  overflow-y: scroll;
`;
