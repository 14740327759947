import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FaHome, FaList, FaSignOutAlt, FaUser, FaUsers } from "react-icons/fa"; // Importa el ícono de logout
import { logoutSuccess } from "../../../context/authSlice";
import { NavbarContainer } from "./NavbarStyles";

const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const handleLogout = () => {
    dispatch(logoutSuccess());
  };
  if (!isAuthenticated) {
    return null;
  }
  return (
    <NavbarContainer>
      <Link to="/">
        <img
          src="/images/Logo-Negro.png"
          alt=""
          style={{ width: "130px", height: "50px" }}
        />
      </Link>
      <ul>
        <li>
          <Link
            to="/dashboard"
            className={currentPath === "/dashboard" ? "active" : ""}
          >
            <FaHome />
            Dashboard
          </Link>
        </li>
        <li>
          <Link
            to="/groups"
            className={currentPath === "/groups" ? "active" : ""}
          >
            <FaUsers />
            Grupos
          </Link>
        </li>
        <li>
          <Link
            to="/activity"
            className={currentPath === "/activity" ? "active" : ""}
          >
            <FaList />
            Actividades
          </Link>
        </li>
        <li>
          <Link
            to="/cuentas"
            className={currentPath === "/cuentas" ? "active" : ""}
          >
            <FaUser />
            Cuenta
          </Link>
        </li>
      </ul>

      <div onClick={handleLogout}>
        <FaSignOutAlt />
        Salir
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
