import {
  FaBeer,
  FaHome,
  FaPaw,
  FaChild,
  FaUsers,
  FaShip,
  FaMoneyBillAlt,
  FaMusic,
  FaCar,
  FaShoppingCart,
  FaHotel,
  FaPlane,
  FaGraduationCap,
  FaBriefcase,
  FaMedkit,
  FaGift,
  FaCamera,
  FaFilm,
  FaUtensils, // Usando FaUtensils como reemplazo para cutlery
  FaUniversity, // Usando FaUniversity como reemplazo para bank
  FaRegImage, // Usando FaRegImage como reemplazo para image
} from "react-icons/fa";

const iconMap = {
  cutlery: <FaUtensils />, // Reemplazando FaCutlery con FaUtensils
  beer: <FaBeer />,
  home: <FaHome />,
  paw: <FaPaw />,
  child: <FaChild />,
  bank: <FaUniversity />, // Reemplazando FaBank con FaUniversity
  users: <FaUsers />,
  ship: <FaShip />,
  money: <FaMoneyBillAlt />,
  music: <FaMusic />,
  car: <FaCar />,
  "shopping-cart": <FaShoppingCart />,
  hotel: <FaHotel />,
  plane: <FaPlane />,
  "graduation-cap": <FaGraduationCap />,
  briefcase: <FaBriefcase />,
  medkit: <FaMedkit />,
  gift: <FaGift />,
  camera: <FaCamera />,
  film: <FaFilm />,
  image: <FaRegImage />, // Reemplazando FaImage con FaRegImage
};

export function getIcon(iconName) {
  return iconMap[iconName] || null;
}
