import React from "react";
import "./NotificationsPage.css";

const NotificationsPage: React.FC = () => {
  return (
    <div className="notifications-page">
      <h2>Notifications</h2>
      <p>
        Here you can view all your notifications related to expenses and group
        invitations.
      </p>
      {/* Add Notifications Components Here */}
    </div>
  );
};

export default NotificationsPage;
