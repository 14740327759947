import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  height: 80vh;
`;

export const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  /* overflow-y: auto; */
`;

export const NavbarContainer = styled.nav`
  position: relative;
  height: calc(100vh - 60px);
  width: 10%;
  background-color: #eec800;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  justify-content: space-between;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 50px;
  }

  li {
    margin-bottom: 30px;
    font-size: 1.4rem;
  }

  a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;

    &.active {
      font-weight: bold;
    }
  }

  svg {
    margin-right: 10px;
  }
`;
