import styled from "styled-components";

export const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 400px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const SplitMethodContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkGrey};
  padding: 20px;
  border-radius: 10px;
`;

export const CustomSplitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const MemberList = styled.div`
  margin-top: 20px;
`;

export const MemberItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  width: 30%;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.yellow};
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
`;

export const Select = styled.select`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
`;
