import api from "./api";

interface Category {
  name: string;
  budget: number;
  icon: string;
  groupId: string;
}

const getBalance = (userId: string) => {
  return api.get(`/balance/account/${userId}`);
};

const getBalanceByCategory = (userId: string) => {
  return api.get(`/balance/category/${userId}`);
};

const balanceService = {
  getBalance,
  getBalanceByCategory,
};

export default balanceService;
