import React, { useState } from "react";
import {
  SplitMethodContainer,
  CustomSplitContainer,
  MemberList,
  MemberItem,
  Input,
  Button,
  Select,
  ModalOverlay,
  ModalContent,
  CloseButton,
} from "./SplitMethodStyles";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../context/notificationSlice";
import { formatCurrency } from "../../../utils/formatCurrency";
import { FaTimes } from "react-icons/fa";

const SPLIT_METHODS = [
  {
    label: "Pagado por mi, dividido a partes iguales",
    value: "equalSplitByMe",
  },
  {
    label: "Pagado por alguien más, pagado a partes iguales",
    value: "equalSplitByOthers",
  },
  {
    label: "Pagado por mi, se te debe la cantidad total",
    value: "totalDebtToMe",
  },
  {
    label: "Pagado por alguien más, se le debe la totalidad",
    value: "totalDebtToOthers",
  },
  { label: "División Personalizada", value: "customSplit" },
];

const SplitMethod = ({
  splitMethod,
  customSplit,
  groups,
  selectedGroup,
  handleInputChange,
  handleAddMember,
  handleRemoveMember,
  closeModal,
}: any) => {
  const dispatch = useDispatch();

  const lastMember = customSplit[customSplit.length - 1];

  const handleAddMemberClick = () => {
    const groupMembers =
      groups.find((group: any) => group._id === selectedGroup)?.members || [];
    if (!lastMember.userId || !lastMember.amount) {
      dispatch(
        addNotification({
          message:
            "Usuario y valor deben estar seteados antes de añadir un nuevo miembro.",
          type: "Error",
        })
      );
      return;
    }
    if (!groupMembers.some((member: any) => member._id === lastMember.userId)) {
      dispatch(
        addNotification({
          message: "El usuario seleccionado no es un miembro del grupo.",
          type: "Error",
        })
      );
      return;
    }
    handleAddMember();
  };

  const handleRemoveMemberClick = (index: number) => {
    dispatch(
      addNotification({
        message: "Miembro eliminado",
        type: "Success",
      })
    );
    handleRemoveMember(index);
  };

  const getAvailableMembers = () => {
    const groupMembers =
      groups.find((group: any) => group._id === selectedGroup)?.members || [];
    return groupMembers.filter(
      (member: any) =>
        !customSplit.some((split: any) => split.userId === member._id)
    );
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={closeModal}>
          <FaTimes />
        </CloseButton>
        <SplitMethodContainer>
          <h2>Dividir la cuenta</h2>
          <Select
            value={splitMethod}
            onChange={(e) => handleInputChange("splitMethod", e.target.value)}
          >
            <option value="" disabled>
              - Seleccione -
            </option>
            {SPLIT_METHODS.map((method) => (
              <option key={method.value} value={method.value}>
                {method.label}
              </option>
            ))}
          </Select>
          {splitMethod === "customSplit" && (
            <>
              <CustomSplitContainer>
                <Select
                  value={lastMember.userId}
                  onChange={(e) => {
                    const updatedSplit = [...customSplit];
                    updatedSplit[customSplit.length - 1].userId =
                      e.target.value;
                    handleInputChange("customSplit", updatedSplit);
                  }}
                >
                  <option value="" disabled>
                    Usuario
                  </option>
                  {getAvailableMembers().map((member: any) => (
                    <option key={member._id} value={member._id}>
                      {member.name}
                    </option>
                  ))}
                </Select>
                <Input
                  placeholder="$"
                  value={lastMember.amount}
                  onChange={(e: any) => {
                    const updatedSplit = [...customSplit];
                    updatedSplit[customSplit.length - 1].amount =
                      e.target.value;
                    handleInputChange("customSplit", updatedSplit);
                  }}
                />
              </CustomSplitContainer>
              <Button onClick={handleAddMemberClick}>+ Añadir Miembro</Button>
              <MemberList>
                {customSplit.map((split: any, index: number) => (
                  <MemberItem key={index}>
                    <span>
                      {
                        groups
                          .find((group: any) => group._id === selectedGroup)
                          ?.members.find(
                            (member: any) => member._id === split.userId
                          )?.name
                      }{" "}
                      ({formatCurrency(split.amount)})
                    </span>
                    <Button onClick={() => handleRemoveMemberClick(index)}>
                      Eliminar
                    </Button>
                  </MemberItem>
                ))}
              </MemberList>
            </>
          )}
          <Button onClick={closeModal}>Guardar División</Button>
          <Button onClick={closeModal}>Cerrar</Button>
        </SplitMethodContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SplitMethod;
