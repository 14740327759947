import React from "react";
import Profile from "../../components/Account/Profile";
import ChangePasswordForm from "../../components/Account/ChangePasswordForm";
import "./AccountPage.css";

const AccountPage: React.FC = () => {
  console.log("aca");
  return (
    <div className="account-page">
      {/* <Profile /> */}
      <h2>asd</h2>
      {/* <ChangePasswordForm /> */}
    </div>
  );
};

export default AccountPage;
