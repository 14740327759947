import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Función para registrar errores de manera consistente
const logError = (message: any, error: any) => {
  console.log(`${message}:`, error);
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = await localStorage.getItem("refreshToken");
    if (!refreshToken) {
      console.log("No refresh token found");
      return null;
    }

    const response = await axios.post(`${API_URL}/auth/refresh-token`, {
      refreshToken,
    });

    const { accessToken } = response.data;
    await localStorage.setItem("accessToken", accessToken);
    return accessToken;
  } catch (e: any) {
    console.log("Error...", e.response);
    logError("Could not refresh token", e);
    return null;
  }
};
