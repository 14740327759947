// src/theme.ts

import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    grey: "#3D3D3D",
    lightGrey: "#BAB9B4",
    yellow: "#EEC800",
    white: "#f1f1f1",
    darkGrey: "#2A2A2A",
    red: "#E26D5A",
    green: "#06B503",
    textGrey: "#7A7A7A",
  },
  button: {
    primary: {
      backgroundColor: "#EEC800",
      padding: "13px 20px",
      color: "#2A2A2A",
      borderRadius: "5px",
      border: "0px",
      cursor: "pointer",

      hover: {
        color: "#f1f1f1",
        backgroundColor: "red",
      },
    },
    secondary: {
      color: "#f1f1f1",
      backgroundColor: "#3D3D3D",
      hoverColor: "#3D3D3D",
      hoverBackgroundColor: "#f1f1f1",
    },
    danger: {
      color: "#f1f1f1",
      backgroundColor: "#E26D5A",
      hoverColor: "#E26D5A",
      hoverBackgroundColor: "#f1f1f1",
    },
  },
  input: {
    backgroundColor: "#3D3D3D",
    color: "#f1f1f1",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid grey",
    marginBottom: "10px",
    width: "100%",
  },
};

export default theme;
