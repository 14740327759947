// src/IconSelectorModalStyles.ts

import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }
`;

export const Box = styled.div`
  background-color: white;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 20px;
`;

export const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Button = styled.button`
  ${({ theme }) => theme.button.primary}

  &:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hover.backgroundColor};
    color: ${({ theme }) => theme.button.primary.hover.color};
  }
`;
