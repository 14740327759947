import React, { useState, useEffect } from "react";
import { AddExpenseContainer, IconContainer } from "./AddExpenseStyles";
import { FaCamera, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import IconSelectorModal from "../../IconSelectorModal/IconSelectorModal";
import { getIcon } from "../../../utils/iconsFormat";
import expenseService from "../../../services/expenseService";
import groupService from "../../../services/groupService";
import { addNotification } from "../../../context/notificationSlice";
import { formatCurrency, parseCurrency } from "../../../utils/formatCurrency";
import CategoryModal from "../CategoryModal/CategoryModal";
import SplitMethod from "../SplitMethod/SplitMethod";

const AddExpense = ({ setAddIsVisible, groupId, onExpenseAdded }: any) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state.auth.user.id);

  const [expenseName, setExpenseName] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [paidBy, setPaidBy] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryIsOpen, setCategoryIsOpen] = useState(false);
  const [splitMethod, setSplitMethod] = useState("equalSplitByMe");
  const [customSplit, setCustomSplit] = useState([{ userId: "", amount: "" }]);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const [groupMembers, setGroupMembers] = useState<any[]>([]);

  useEffect(() => {
    const fetchGroupMembers = async () => {
      try {
        const { data } = await groupService.getGroupById(groupId);
        setGroupMembers(data.members);
      } catch (error) {
        console.error("Error fetching group members", error);
        dispatch(
          addNotification({
            message: "Error al obtener miembros del grupo",
            type: "Error",
          })
        );
      }
    };

    fetchGroupMembers();
  }, [groupId, dispatch]);

  const handleIconSelect = (iconName: any) => {
    setSelectedIcon(iconName);
  };

  const handleAddExpense = async () => {
    if (!expenseName || !amount || !date || !paidBy || !categoryId) {
      dispatch(
        addNotification({
          message: "Por favor, completa todos los campos",
          type: "Error",
        })
      );
      return;
    }

    const splitDetails =
      splitMethod === "customSplit"
        ? customSplit.map((split) => ({
            debtorId: split.userId,
            amount: parseCurrency(split.amount),
          }))
        : groupMembers.map((member: any) => ({
            debtorId: member._id,
            amount: parseCurrency(amount) / groupMembers.length,
          }));

    try {
      console.log({
        name: expenseName,
        amount: parseCurrency(amount),
        date: new Date(date),
        paidBy: userId,
        categoryId,
        icon: selectedIcon,
        groupId,
        ownerId: userId,
        splitDetails,
        totalExpenses: "",
      });
      await expenseService.addExpense({
        name: expenseName,
        amount: parseCurrency(amount),
        date: new Date(date),
        paidBy: userId,
        categoryId,
        icon: selectedIcon,
        groupId,
        ownerId: userId,
        splitDetails,
        totalExpenses: "",
      });

      dispatch(
        addNotification({
          message: "Gasto agregado exitosamente",
          type: "Success",
        })
      );
      setAddIsVisible(false); // Cierra el modal
      onExpenseAdded();
    } catch (err) {
      console.error("Error al agregar gasto", err);
      dispatch(
        addNotification({
          message: "No se pudo agregar el gasto",
          type: "Error",
        })
      );
    }
  };

  const handleCategorySelect = (category: any) => {
    setCategoryId(category._id);
    setCategoryName(category.name);
  };

  const handleInputChange = (field: string, value: any) => {
    if (field === "splitMethod") {
      setSplitMethod(value);
    } else if (field === "customSplit") {
      setCustomSplit(value);
    }
  };

  const handleAddMember = () => {
    setCustomSplit([...customSplit, { userId: "", amount: "" }]);
  };

  const handleRemoveMember = (index: number) => {
    const updatedSplit = [...customSplit];
    updatedSplit.splice(index, 1);
    setCustomSplit(updatedSplit);
  };

  return (
    <>
      <AddExpenseContainer>
        <div>
          <FaTimes onClick={() => setAddIsVisible(false)} />

          <h1>Agregar Gasto</h1>

          <IconContainer onClick={() => setIsModalOpen(true)}>
            {selectedIcon ? getIcon(selectedIcon) : <FaCamera />}
          </IconContainer>
          <input
            type="text"
            placeholder="Nombre del gasto"
            value={expenseName}
            onChange={(e) => setExpenseName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Valor"
            value={formatCurrency(amount)}
            onChange={(e) => setAmount(e.target.value)}
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <select value={paidBy} onChange={(e) => setPaidBy(e.target.value)}>
            <option value="" disabled>
              - Pagado Por -
            </option>

            {groupMembers.map((member: any) => (
              <option key={member._id} value={member._id}>
                {member.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={categoryName}
            readOnly
            placeholder="Selecciona una categoría"
            onClick={() => setCategoryIsOpen(true)}
          />
        </div>
        <div>
          <button onClick={() => setIsSplitModalOpen(true)}>
            Dividir Gastos
          </button>
          <button onClick={handleAddExpense}>Guardar Gastos</button>
        </div>

        {isModalOpen && (
          <IconSelectorModal
            onClose={() => setIsModalOpen(false)}
            onIconSelect={handleIconSelect}
          />
        )}
      </AddExpenseContainer>
      {categoryIsOpen && (
        <CategoryModal
          setCategoryIsOpen={setCategoryIsOpen}
          groupId={groupId}
          onCategorySelect={handleCategorySelect}
        />
      )}
      {isSplitModalOpen && (
        <SplitMethod
          splitMethod={splitMethod}
          customSplit={customSplit}
          groups={[{ _id: groupId, members: groupMembers }]}
          selectedGroup={groupId}
          handleInputChange={handleInputChange}
          handleAddMember={handleAddMember}
          handleRemoveMember={handleRemoveMember}
          closeModal={() => setIsSplitModalOpen(false)}
        />
      )}
    </>
  );
};

export default AddExpense;
