import React, { useEffect, useState } from "react";
import {
  CardContainer,
  ExpensesContainer,
  MainCard,
  ProgressBarContainer,
} from "./DashboardStyles";
import ProgressCircle from "../../components/Common/ProgressCircle/ProgressCircle";
import ProgressBar from "../../components/Common/ProgressBar/ProgressBar";
import ExpenseCard from "../../components/Common/ExpenseCard/ExpenseCard";
import balanceService from "../../services/balanceService";
import { useSelector } from "react-redux";

const DashboardPage: React.FC = () => {
  const [categories, setCategories] = useState([]);
  const userId = useSelector((state: any) => state.auth.user.id);

  const fetchBalanceByCategory = async () => {
    const response = await balanceService.getBalanceByCategory(userId);

    setCategories(response.data as any);
  };

  useEffect(() => {
    fetchBalanceByCategory();
  }, []);

  return (
    <div>
      <div>
        <CardContainer>
          <ProgressCircle budget={5000000} expenses={1000000} />
          <MainCard>
            <h3>Debes</h3>
            <h3>$200.000</h3>
            <h4>Grupo: Casa Junio</h4>
          </MainCard>
          <MainCard>
            <h3>Te Deben</h3>
            <h3>$200.000</h3>
            <h4>Grupo: Casa Junio</h4>
          </MainCard>
          <MainCard>
            <p>Card 3</p>
          </MainCard>
        </CardContainer>
      </div>
      <div>
        <h2 style={{ color: "white" }}>Gastos por categorías</h2>
        <ProgressBarContainer>
          {categories.map((category: any) => (
            <ProgressBar
              key={category.categoryName}
              name={category.categoryName}
              spent={category.total}
              budget={category.budget}
            />
          ))}
        </ProgressBarContainer>
      </div>
      <div>
        <h2 style={{ color: "white" }}>Ultima Actividad</h2>
        <ExpensesContainer>
          <ExpenseCard
            icon="camera"
            userName="userName"
            groupName="groupName"
            balance="0"
            date="12/12/2024"
            expenseName="ExpenseName"
            categoryId={{ name: "Sin Categoria" }}
          />
        </ExpensesContainer>
      </div>
    </div>
  );
};

export default DashboardPage;
