import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaKey } from "react-icons/fa";
import { loginSuccess } from "../context/authSlice";
import { loginService } from "../services/authService";
import { addNotification } from "../context/notificationSlice";
import { Container, InputContainer } from "./LoginPageStyles";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await loginService(email, password);
      const { accessToken, refreshToken, user } = response;
      if (accessToken && refreshToken) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        dispatch(loginSuccess({ accessToken, refreshToken, user }));
        navigate("/dashboard");
        dispatch(
          addNotification({
            message: "Sesion Iniciada",
            title: "Exito!",
            type: "Success",
          })
        );
      } else {
        console.error("Invalid login response");
      }
    } catch (err: any) {
      dispatch(
        addNotification({
          message: err.response.data.message,
          title: "Error",
          type: "Error",
        })
      );
      console.error("Failed to login", err);
    }
  };

  return (
    <>
      <div>
        <Container>
          <div>
            <h2>Ingresa a tu cuenta</h2>
            <form className="login-form" onSubmit={handleSubmit}>
              <label htmlFor="email">
                Correo Electrónico <span style={{ color: "#E26D5A" }}>*</span>
              </label>
              <InputContainer>
                <FaEnvelope />
                <input
                  autoComplete="email"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </InputContainer>
              <label htmlFor="password">
                Contraseña <span style={{ color: "#E26D5A" }}>*</span>
              </label>
              <InputContainer>
                <FaKey
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                />
                <input
                  autoComplete="current-password"
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </InputContainer>
              <label
                style={{
                  color: "#E26D5A",
                  textDecoration: "underline",
                  marginTop: "20px",
                }}
              >
                Olvide la contraseña
              </label>
              <button type="submit">Iniciar Sesión</button>
              <h4>Crear una cuenta</h4>
            </form>
          </div>
          <div>
            <img
              src="/images/Logo.png"
              alt=""
              width={200}
              style={{ marginBottom: "50px" }}
            />
            <h2>Lleva tus cuentas mes a mes</h2>
            <p>
              Crea presupuestos para todos tus gastos y lleva ordedamente las
              cuentas{" "}
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default LoginPage;
