import React from "react";
import "./BudgetPage.css";

const BudgetPage: React.FC = () => {
  return (
    <div className="budget-page">
      <h2>Budget Management</h2>
      <p>Here you can manage your monthly and category budgets.</p>
      {/* Add Budget Management Components Here */}
    </div>
  );
};

export default BudgetPage;
