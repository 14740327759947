import api from "./api";
export interface IExpense {
  _id?: string;
  name?: string;
  amount?: number;
  date?: Date;
  splitMethod?: string;
  splitDetails?: {
    debtorId: { _id: string; name: string };
    amount: number;
  }[];
  groupId?: any;
  paidBy?: { _id: string; name: string };
  ownerId?: any;
  icon?: string;
  categoryId?: any;
  totalExpenses: string;
  createdAt?: Date;
}

interface SplitDetails {
  userId: string;
  percentage: number;
}

const addExpense = (expense: IExpense) => {
  return api.post("/expenses", expense);
};

const getExpenses = (userId: string) => {
  return api.get(`/expenses?userId=${userId}`);
};

const getExpensesByGroupId = (groupId: string, userId: string) => {
  return api.get(`/expenses/groupId/${groupId}?userId=${userId}`);
};

const deleteExpense = (id: string) => {
  return api.delete(`/expenses/${id}`);
};

const updateExpense = (id: string, expense: IExpense) => {
  return api.put(`/expenses/${id}`, expense);
};

const expenseService = {
  addExpense,
  getExpenses,
  getExpensesByGroupId,
  deleteExpense,
  updateExpense,
};

export default expenseService;
