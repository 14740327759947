// src/index.tsx

import React from "react";
import ReactDOM from "react-dom/client"; // Importar desde 'react-dom/client'
import { Provider } from "react-redux";
import store from "./context/store";
import App from "./App";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
