import React from "react";
import "./ReportsPage.css";

const ReportsPage: React.FC = () => {
  return (
    <div className="reports-page">
      <h2>Reports</h2>
      <p>
        Here you can generate and download detailed reports of your expenses.
      </p>
      {/* Add Report Generation Components Here */}
    </div>
  );
};

export default ReportsPage;
