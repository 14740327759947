// src/ProgressCircle.tsx

import React from "react";
import {
  BudgetInfo,
  Circle,
  CircleBG,
  CircularChart,
  Container,
  PercentageText,
} from "./ProgressCircleStyles";

interface ProgressCircleProps {
  budget: number;
  expenses: number;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  budget,
  expenses,
}) => {
  const percentage = Math.min((expenses / budget) * 100, 100);

  return (
    <Container>
      <CircularChart viewBox="0 0 36 36">
        <CircleBG
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <Circle
          percentage={percentage}
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <PercentageText x="18" y="20.35">{`${percentage.toFixed(
          0
        )}%`}</PercentageText>
      </CircularChart>
      <BudgetInfo>
        <p>
          ${expenses.toLocaleString()} / ${budget.toLocaleString()}
        </p>
        <p>Presupuesto Mensual</p>
      </BudgetInfo>
    </Container>
  );
};

export default ProgressCircle;
