import React from "react";
import "./HistoryPage.css";

const HistoryPage: React.FC = () => {
  return (
    <div className="history-page">
      <h2>Activity History</h2>
      <p>Here you can view a detailed history of all activities and changes.</p>
      {/* Add Activity History Components Here */}
    </div>
  );
};

export default HistoryPage;
